// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/solid';

.card-header{
    color: $orange;
    font-size: larger;
    font-weight: bold;
}

.orange-button{
    background-color: $orange;
    opacity: 90%;
    color: white;
}

.inline-icon{
    width: 24px;
    height: 24px;
    display: inline-block;
    margin-left: 6px;
    background-size: 18px;
    background-repeat: no-repeat;
    background-position: 3px;
    &:hover{
        cursor: pointer;
        background-color: $orange;
    }
}
.righttop-icon{
    float: right;
    width: 27px;
    height: 27px;
    background-size: 21px;
    background-repeat: no-repeat;
    background-position: 3px;
    &:hover{
        cursor: pointer;
        background-color: $orange;
    }
}
ul.no-bullets{
    list-style-type: none;
    margin-left: 0;
    padding-left: 0;
}
ul.no-bullets>li>*{
    display: inline-block;
    vertical-align: middle;
}
.edit-icon{
    background-image: url('../icons/pencil-square.svg');
}

.delete-icon{
    background-image: url('../icons/x-square-fill.svg');
}

.search-icon{
    background-image: url('../icons/search.svg');
}

.location-icon{
    background-image: url('../icons/house-door.svg');
}

.add-icon{
    background-image: url('../icons/plus-square-fill.svg');
}

.cal-icon{
    background-image: url('../icons/calendar2-check.svg');
}

.caret-down-icon{
    background-image: url('../icons/caret-down-fill.svg');
}

.caret-right-icon{
    background-image: url('../icons/caret-right-fill.svg');
}

.locked-icon{
    background-image: url('../icons/lock.svg');
}

.unlocked-icon{
    background-image: url('../icons/unlock.svg');
}

.question-icon{
    background-image: url('../icons/question-square.svg');
}

.navbar-brand{
    margin-left: 60px;
    font-size: 2em;
}

div.top-links{
    margin-bottom: 10px;
}

.main-logo img{
    width: 250px;
}
.nowrap{
    white-space:nowrap;
}
